//Action Creator
import types from "./types";

export const jobmarketInitialized = (state) => ({
  type: types.JOBMARKET_INITIALIZED,
  state,
});

export const setSearchFilter = (filter) => ({
    type: types.SET_SEARCH_FILTER,
    payload: {filter}
});

export const toggleSearchFilter = (key, value) => ({
  type: types.TOGGLE_SEARCH_FILTER,
  payload: { key, value },
});

export const setSearchQuery = (query) => ({
  type: types.SET_SEARCH_QUERY,
  payload: query,
});

export const setSearchLocation = (location) => ({
  type: types.SET_SEARCH_LOCATION,
  payload: location,
});

export const setSearchLocationRadius = (radius) => ({
  type: types.SET_SEARCH_LOCATION_RADIUS,
  payload: radius,
});

export const setSorting = (sortType, sortDirection) => ({
  type: types.SET_SORTING,
  payload: { sortType, sortDirection },
});

export const setUser = (user) => ({
  type: types.SET_USER,
  payload: { user },
});

export const fetchJobSearchPending = () => ({
  type: types.FETCH_JOB_SEARCH_PENDING,
});

export const fetchJobSearchSuccess = (
  searchArgs,
  data,
  isDefaultSearch,
  title,
  timestamp
) => ({
  type: types.FETCH_JOB_SEARCH_SUCCESS,
  searchArgs,
  data,
  isDefaultSearch,
  title,
  timestamp,
});

export const fetchJobSeoSearchSuccess = (
  seoConfig,
  data,
  title,
  timestamp
) => ({
  type: types.FETCH_JOB_SEO_SEARCH_SUCCESS,
  seoConfig,
  data,
  title,
  timestamp,
});

export const fetchJobSearchError = (error) => ({
  type: types.FETCH_JOB_SEARCH_ERROR,
  error: error,
});

export const moveLastSearchToHistory = () => ({
  type: types.MOVE_LAST_SEARCH_TO_HISTORY,
});

export const moveSearchHistoryItemToTop = (index) => ({
  type: types.MOVE_SEARCH_HISTORY_ITEM_TO_TOP,
  index,
});

export const moveJobToHistory = (job) => ({
  type: types.MOVE_JOB_TO_HISTORY,
  job,
});

export const saveBoosting = (boosting) => ({
  type: types.SAVE_BOOSTING,
  boosting,
});

export const setSearchHistory = (searchHistory) => ({
  type: types.SET_SEARCH_HISTORY,
  searchHistory,
});

export const searchHistoryInitialized = (initiallized = true) => ({
  type: types.SEARCH_HISTORY_INITIALIZED,
  payload: initiallized,
});

export const setJobsHistory = (jobsHistory) => ({
  type: types.SET_JOBS_HISTORY,
  jobsHistory,
});

export const newsletterSubscribeClear = () => ({
  type: types.NEWSLETTER_SUBSCRIBE_CLEAR,
});

export const newsletterSubscribeSuccess = (messages) => ({
  type: types.NEWSLETTER_SUBSCRIBE_SUCCESS,
  messages,
});

export const newsletterSubscribeFailed = (errors) => ({
  type: types.NEWSLETTER_SUBSCRIBE_FAILED,
  errors,
});

export const setLastVisit = (timestamp) => ({
  type: types.SET_LAST_VISIT,
  timestamp,
});
