import Fetch, { fetchData } from "../../../../libs/Fetch";
import GeneralUtility from "../../../../libs/GeneralUtility";

const resultsPerPage = 20;

// TODO move to state
const sorting = "rel_desc";

// const additionalParams = 'type=1578058625';
const apiBasePath = "/rest/v1/jobs";

const fetch = (url, payload = {}, method = "GET", sessionId = null) => {
  url = apiBasePath + url;

  // url += url.indexOf('?') > 0 ? '&' : '?';
  // url += additionalParams;
  let headers = {};
  if (sessionId) {
    headers["Cookie"] = `fe_typo_user=${sessionId}`;
  }

  return fetchData(url, payload, method, true, { headers });
};

/**
 * Fetch search request
 * @param data
 * @param boosting
 * @param page
 * @param seoConfig
 * @param isPreview
 * @param sessionId
 */
const fetchSearch = (
  data = {},
  boosting = [],
  page = 1,
  seoConfig = {},
  isPreview = false,
  sessionId = null
) => {
  let url = "/search/";

  if (page > 1) {
    url += "page/" + page + "/";
  }

  if (!"resultsPerPage" in data) {
    // set default
    data["resultsPerPage"] = resultsPerPage;
  }

  if (!"sort" in data) {
    // set default
    data["sort"] = sorting;
  }

  let request = { params: data };

  if ((boosting && data["sort"] === "rel_desc") || data["sort"] === "rel_asc") {
    if (!Array.isArray(boosting) && !GeneralUtility.isEmptyObject(boosting)) {
      boosting = [boosting];
    }

    if (boosting.length > 0) {
      request["boosting"] = boosting;
    }
  }

  if (isPreview) {
    url = `${url}?no_varnish=1`;
  }
  return fetch(url, request, "POST", sessionId);
};

const fetchSeoSearch = (searchConfig, page = 1, sessionId = null) => {
  let url = "/search/";

  if (page > 1) {
    url += "page/" + page + "/";
  }

  searchConfig.seoPage.resultsPerPage = resultsPerPage;

  return fetch(url, searchConfig, "POST", sessionId);
};

const fetchTopJobs = (limit) => {
  let url = "/topjobs/";

  // let request = {'params': {
  //     resultsPerPage: limit
  // }};

  return fetch(url, {}, "POST");
};

const fetchJobStatistic = (jobUid, statisticToken) => {
  let url = `/show-statistic/${jobUid}/`;

  let request = {
    statisticSecure: statisticToken,
  };

  return fetch(url, request, "POST");
};

const expandJob = (jobUid, statisticToken) => {
  let url = `/expand-job/${jobUid}`;

  let request = {
    statisticSecure: statisticToken,
  };

  return fetch(url, request, "POST").then((res) => {
    if (res.status === "processing_ok") {
      if (res.messages?.[0].code === "1624008056") {
        return true;
      }
    }
    return false;
  });
};

const fetchJobsCountByTimeLimits = (from, to = 0, searchConfig = {}) => {
  let url = `/search/count`;

  let request = {
    timeLimits: {
      from,
      to,
    },
  };

  if (!GeneralUtility.isEmptyObject(searchConfig)) {
    request["params"] = searchConfig;
  }

  return fetch(url, request, "POST");
};

/**
 * Fetch job details
 * @param jobUid
 * @returns {*}
 */
const fetchJob = (jobUid, isPreview) => {
  let payload = {};

  if (isPreview) {
    payload.isPreview = isPreview;
  }

  return fetch(`/show/${jobUid}/`, payload, "POST");
};

const fetchNewsletterSubscription = (searchConfig, subscriptionConfig) => {
  const url = "/newsletter/registration/";

  if (Object.keys(searchConfig).length) {
    //TODO brauchen wir das noch?
    // this.lastSearch = searchConfig;
  }
  return fetch(
    url,
    { params: searchConfig, subscription: subscriptionConfig },
    "PUT"
  );
};

const fetchNewsletterOptInConfirm = (hash) => {
  const url = "/newsletter/confirm/";
  return fetch(url, { subscription: { confirmToken: hash } }, "POST");
};

const fetchNewsletterDelete = (hash) => {
  const url = "/newsletter/delete/";
  let request = { subscription: { confirmToken: hash } };
  return fetch(url, request, "DELETE");
};

const fetchAutosuggest = (text, type) => {
  const url = "/suggest/" + type + "/";
  let request = { query: text };
  return fetch(url, request, "POST");
};

const fetchAutosuggestZip = (text) => {
  const url = "/suggest/locationzip/";
  let request = { query: text };
  return fetch(url, request, "POST");
};

const fetchAutosuggestLocationName = (text) => {
  const url = "/suggest/location/";
  let request = { query: text, options: { includeRemoteOnly: true } };
  return fetch(url, request, "POST");
};

const countJobView = (jobUid, sessionId) => {
  const url = "/viewcounter";
  let request = {
    uidJob: jobUid,
    type: 0,
    sessionHash: sessionId,
  };
  return fetch(url, request, "POST");
};

const countJobApply = (jobUid, sessionId) => {
  const url = "/viewcounter";
  let request = {
    uidJob: jobUid,
    type: 1,
    sessionHash: sessionId,
  };
  return fetch(url, request, "POST");
};

// TODO move to state!
const toggleSorting = (element) => {
  // const sortString = element.value;
  // const currentSortConfig = sortString.split('_');
  //
  // const sortField = currentSortConfig[0];
  // let sortDirection = currentSortConfig[1];
  //
  // if(this.sorting.includes(sortField)) {
  //     // toggle current sorting type
  //
  //     if(sortDirection === 'asc') {
  //         sortDirection = 'desc';
  //     }else {
  //         sortDirection = 'asc';
  //     }
  //     this.sorting = sortField + '_' + sortDirection;
  // }else {
  //     // sort field changed => set initial value
  //     this.sorting = sortString;
  // }
  return sorting;
};

// TODO move to state!
const getSorting = () => {
  return sorting;
};

export default {
  fetchSearch,
  fetchSeoSearch,
  fetchTopJobs,
  fetchJob,
  fetchNewsletterSubscription,
  fetchNewsletterOptInConfirm,
  fetchNewsletterDelete,
  fetchAutosuggest,
  fetchAutosuggestZip,
  fetchAutosuggestLocationName,
  toggleSorting,
  getSorting,
  fetchJobStatistic,
  fetchJobsCountByTimeLimits,
  countJobView,
  countJobApply,
  expandJob,
};
