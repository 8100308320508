import getConfig from "next/config";
import Link from "next/link";
import GeneralUtility from "../../../libs/GeneralUtility";

export const getRoles = (user) => {
  let roles = [];
  if (user?.usergroup) {
    const usergroups = user?.usergroup ? [...user.usergroup] : [];
    for (const usergroup of usergroups) {
      if (Array.isArray(usergroup.features) && usergroup.features.length > 0) {
        roles = [...roles, ...usergroup.features];
      }
    }
  }
  return roles;
};

export const hasRole = (role, user) => {
  const roles = getRoles(user);
  return roles.includes(role);
};

export const hasUsergroup = (usergroupId, user) => {
  return !!user?.usergroup?.find(
    (usergroup) => parseInt(usergroup.uid) === parseInt(usergroupId)
  );
};

export const getCurrentLevelByUsergroups = (user) => {
  const { publicRuntimeConfig } = getConfig();
  if (hasUsergroup(publicRuntimeConfig.usergroups.expert.uid, user)) {
    return "expert";
  } else if (hasUsergroup(publicRuntimeConfig.usergroups.advanced.uid, user)) {
    return "advanced";
  } else if (hasUsergroup(publicRuntimeConfig.usergroups.basic.uid, user)) {
    return "basic";
  }
  return "default";
};

export const getNextLevelByUsergroups = (user) => {
  const { publicRuntimeConfig } = getConfig();
  if (hasUsergroup(publicRuntimeConfig.usergroups.expert.uid, user)) {
    return "completed";
  } else if (hasUsergroup(publicRuntimeConfig.usergroups.advanced.uid, user)) {
    return "expert";
  } else if (hasUsergroup(publicRuntimeConfig.usergroups.basic.uid, user)) {
    return "advanced";
  }
  return "basic";
};

export const compareUserLevels = (level1, level2) => {
  const sortedLevels = ["default", "basic", "advanced", "expert", "completed"];
  const indexLevel1 = sortedLevels.findIndex((level) => level === level1);
  const indexLevel2 = sortedLevels.findIndex((level) => level === level2);

  return indexLevel2 - indexLevel1;
};

export const getFeatureNotAllowedTooltip = (
  isLoggedInUser,
  slogan,
  loginSlogan,
  levelKey = "",
  description = ""
) => {
  const { publicRuntimeConfig } = getConfig();
  const loginUrl = GeneralUtility.isServerSide()
    ? `${publicRuntimeConfig.pagePaths.login}`
    : `${publicRuntimeConfig.pagePaths.login}?callbackUrl=${encodeURI(
        window.location?.pathname || ""
      )}`;

  const userProfilFormUrl = `${publicRuntimeConfig.pagePaths.userWizard}#${levelKey}`;

  let tooltipText = "";
  if (isLoggedInUser) {
    tooltipText = (
      <>
        {slogan && <h5 className="">{slogan}</h5>}
        {description ? (
          description
        ) : (
          <p>
            Leider fehlen uns noch Informationen, <br />
            um dir diese Funktion anbieten zu können.
          </p>
        )}
        <div className="text-right">
          <Link href={userProfilFormUrl}>
            <a className="button button--primary">
              Jetzt Profil vervollständigen
            </a>
          </Link>
        </div>
      </>
    );
  } else {
    tooltipText = (
      <>
        <p>
          {slogan && (
            <>
              {slogan}
              <br />
            </>
          )}
          {loginSlogan || "Melde Dich an, um dieses Feature nutzen zu können."}
        </p>
        <Link href={loginUrl}>
          <a className="button button--primary">Login</a>
        </Link>
      </>
    );
  }

  return (
    <div
      onClick={(e) => {
        // don't propagate click to parent elements (elements behind)
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {tooltipText}
    </div>
  );
};
