/*********************************
 * *******************************
 * Start - Search history operations
 * *******************************
 * *******************************
 */

import {
  moveJobToHistory,
  moveLastSearchToHistory,
  moveSearchHistoryItemToTop,
  saveBoosting,
  searchHistoryInitialized,
  setJobsHistory,
  setSearchHistory,
} from "../actions";
import {
  getJobsHistory,
  getSearch,
  getSearchArgs,
  getSearchHistory,
} from "../reducer";
import GeneralUtility from "../../../libs/GeneralUtility";
import { isEmptyValue } from "../../user/lib/GeneralUtility";
import { getStorage } from "../../../libs/StorageUtility";

/**
 * Add last search to history. Check duplicate item before last search added to history
 * if same search config found in history items => trigger move item to top
 * @returns {function(*, *)}
 */
export const addSearchToHistory = () => {
  return async (dispatch, getState) => {
    const storage = getStorage();
    if (storage) {
      const searchHistory = getSearchHistory(getState());
      const lastSearch = getSearch(getState());
      const similarHistoryEntry = getSimilarHistoryEntry(
        searchHistory,
        lastSearch
      );

      if (similarHistoryEntry) {
        dispatch(moveSearchHistoryItemToTop(similarHistoryEntry));
      } else {
        dispatch(moveLastSearchToHistory());
        dispatch(createBoosting());
      }

      // Persist search history to local storage after search history elements changed
      storage.setItem(
        "searchHistory",
        JSON.stringify(getSearchHistory(getState()))
      );
    }
  };
};

export const addJobToHistory = (job) => {
  return async (dispatch, getState) => {
    const storage = getStorage();
    if (storage) {
      const searchHistory = getSearchHistory(getState());
      if (!searchHistory || searchHistory.length === 0) {
        let jobsHistory = getJobsHistory(getState());

        // try to history if empty
        if (jobsHistory.length === 0) {
          dispatch(loadJobsHistory());
          jobsHistory = getJobsHistory(getState());
        }

        const result = jobsHistory.filter((jobHistoryItem) => {
          return jobHistoryItem.uid === job.uid;
        });

        if (
          jobsHistory.filter((jobHistoryItem) => jobHistoryItem.uid === job.uid)
            .length === 0
        ) {
          // we only need uids
          const reducedJob = (({
            uid,
            activities,
            rechtsgebiete,
            locations,
          }) => ({
            uid,
            activities: Array.isArray(activities)
              ? activities.map((item) => item.uid)
              : [],
            rechtsgebiete: Array.isArray(rechtsgebiete)
              ? rechtsgebiete.map((item) => item.uid)
              : [],
            cities: Array.isArray(locations)
              ? locations.map((location) => location.city.uid)
              : [],
          }))(job);

          dispatch(moveJobToHistory(reducedJob));

          // Persist search history to local storage after search history elements changed
          storage.setItem(
            "jobsHistory",
            JSON.stringify(getJobsHistory(getState()))
          );

          // recreate boosting if boosting is based in last visited jobs
          const searchHistory = getSearchHistory(getState());
          if (searchHistory.length === 0) {
            dispatch(createBoosting());
          }
        }
      }
    }
  };
};

export const createBoosting = () => {
  return async (dispatch, getState) => {
    const storage = getStorage();
    if (storage) {
      const searchHistory = getSearchHistory(getState());

      let boosting = [];
      if (searchHistory.length > 0) {
        boosting = getBoostingBySearchHistory(searchHistory);
      } else if (getJobsHistory(getState()).length > 0) {
        boosting = getBoostingByJobsHistory(getJobsHistory(getState()));
      }

      if (boosting.length > 0) {
        // persist latest boosting (used by lto.de job widget to load matching jobs)
        storage.setItem("boosting", JSON.stringify(boosting));
        dispatch(saveBoosting(boosting));
      }
    }
  };
};

export const loadHistory = () => {
  return async (dispatch, getState) => {
    const storage = getStorage();
    if (storage) {
      if (getSearchHistory(getState()).length === 0) {
        // only load search history if not loaded
        dispatch(loadSearchHistory());

        // check search history exists. Otherwise load job history (if exists)
        if (getSearchHistory(getState()).length === 0) {
          dispatch(loadJobsHistory());
        }
      }
      dispatch(searchHistoryInitialized(true));
    }
  };
};

export const loadSearchHistory = () => {
  return async (dispatch, getState) => {
    // only load if not already loaded
    const storage = getStorage();
    if (storage) {
      if (getSearchHistory(getState()).length === 0) {
        let searchHistoryString = storage.getItem("searchHistory");

        // clear history with old history scheme
        if (
          searchHistoryString &&
          (searchHistoryString === "" ||
            searchHistoryString.includes("rawSearchArgs"))
        ) {
          searchHistoryString = "[]";
          storage.removeItem("searchHistory");
        }

        const searchHistory = JSON.parse(searchHistoryString) || [];

        dispatch(setSearchHistory(searchHistory));
        dispatch(createBoosting());
      }
    }
  };
};

export const loadJobsHistory = () => {
  return async (dispatch, getState) => {
    // only load if not already loaded
    const storage = getStorage();
    if (storage && getJobsHistory(getState()).length === 0) {
      const jobsHistoryString = storage.getItem("jobsHistory");
      const jobsHistory = JSON.parse(jobsHistoryString) || [];

      dispatch(setJobsHistory(jobsHistory));
      dispatch(createBoosting());
    }
  };
};

/**
 * ##### End of operations ##################################################################################
 * #####################################################################################################
 * ##### Start helper functions ########################################################################
 */

/***
 * Create boosting configuration by search history
 * @param history
 * @returns {Array}
 */

const getBoostingBySearchHistory = (history) => {
  let boosting = [];
  for (const historyItemPosition in history) {
    const historyItem = history[historyItemPosition];

    if (!historyItem) {
      continue;
    }

    let boostingItem = {};

    if (
      !isEmptyValue(historyItem.args) &&
      "query" in historyItem.args &&
      historyItem.meta.queries
    ) {
      boostingItem["queries"] = historyItem.meta.queries;
    }

    if (
      !isEmptyValue(historyItem.args) &&
      "filter" in historyItem.args &&
      !GeneralUtility.isEmptyObject(historyItem.args.filter)
    ) {
      boostingItem["filter"] = historyItem.args.filter;
    }

    if (
      !isEmptyValue(historyItem.meta) &&
      "geo" in historyItem.meta &&
      historyItem.meta?.geo?.uid
    ) {
      if (
        !boostingItem.filter ||
        !boostingItem.filter.geodatas ||
        !Array.isArray(boostingItem.filter.geodatas)
      ) {
        boostingItem.filter = {
          ...(boostingItem.filter || []),
          ["geodatas"]: [
            ...(boostingItem.filter?.geodatas || []),
            historyItem.meta?.geo?.uid,
          ],
        };
      }
    }

    // if('location' in historyItem.searchArgsFormated && historyItem.searchArgsFormated.location !== '') {
    //     boostingItem['location'] = historyItem.searchArgsFormated.location;
    // }

    if (!GeneralUtility.isEmptyObject(boostingItem)) {
      boosting.push(boostingItem);
    }
  }
  return boosting;
};

const getBoostingByJobsHistory = (history) => {
  let boosting = [];
  for (const historyItemPosition in history) {
    const historyItem = history[historyItemPosition];

    let boostingItem = {};

    boostingItem["filter"] = {
      rechtsgebiete: historyItem.rechtsgebiete,
      geodatas: historyItem.cities,
      activity: historyItem.activities,
    };

    if (!GeneralUtility.isEmptyObject(boostingItem.filter)) {
      boosting.push(boostingItem);
    }
  }
  return boosting;
};

const getSimilarHistoryEntry = (searchHistory, search) => {
  for (const historyItemPosition in searchHistory) {
    const historyItem = searchHistory[historyItemPosition];

    if (!historyItem || !search || !"args" in historyItem) {
      continue;
    }

    if (
      historyItem.args &&
      search &&
      GeneralUtility.compareObject(historyItem.args, search.args, ["sort"])
    ) {
      return historyItemPosition;
    }
  }
  return null;
};
