import JobsApiServiceFrontend from "./Frontend";
import JobsApiServiceBackend from "./Backend";

const backendApiService = JobsApiServiceBackend;
const frontendApiService = JobsApiServiceFrontend;

const requestHandler =
  typeof window !== "undefined" ? frontendApiService : backendApiService;

export const fetchSearch = (
  data = {},
  boosting = [],
  page = 1,
  seoConfig = {},
  isPreview = false,
  sessionId = null
) => {
  return requestHandler.fetchSearch(
    data,
    boosting,
    page,
    seoConfig,
    isPreview,
    sessionId
  );
};

export const fetchJob = (jobUid, isPreview) =>
  backendApiService.fetchJob(jobUid, isPreview);

export const fetchSeoSearch = (searchConfig, page = 1, sessionId = null) =>
  requestHandler.fetchSeoSearch(searchConfig, page, sessionId);

export const fetchTopJobs = (limit) => requestHandler.fetchTopJobs(limit);

export const fetchJobStatistic = (jobUid, statisticToken) =>
  backendApiService.fetchJobStatistic(jobUid, statisticToken);

export const expandJob = (jobUid, statisticToken) =>
  backendApiService.expandJob(jobUid, statisticToken);

export const fetchJobsCountByTimeLimits = (from, to = 0, searchConfig = {}) =>
  backendApiService.fetchJobsCountByTimeLimits(from, to, searchConfig);

export const fetchNewsletterSubscription = (searchConfig, subscriptionConfig) =>
  backendApiService.fetchNewsletterSubscription(
    searchConfig,
    subscriptionConfig
  );

export const fetchNewsletterOptInConfirm = (hash) =>
  backendApiService.fetchNewsletterOptInConfirm(hash);

export const fetchNewsletterDelete = (hash) =>
  backendApiService.fetchNewsletterDelete(hash);

export const fetchAutosuggest = (text, type) =>
  backendApiService.fetchAutosuggest(text, type);

export const fetchAutosuggestZip = (text) =>
  backendApiService.fetchAutosuggestZip(text);

export const fetchAutosuggestLocationName = (text) =>
  backendApiService.fetchAutosuggestLocationName(text);

export const countJobView = (jobUid, sessionId) =>
  backendApiService.countJobView(jobUid, sessionId);

export const countJobApply = (jobUid, sessionId) =>
  backendApiService.countJobApply(jobUid, sessionId);

export const getDefaultSearch = () => {
  return {
    query: "",
    radius: 50,
    sort: "rel_desc",
  };
};
