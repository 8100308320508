import GeneralUtility from "../../../../libs/GeneralUtility";
import { fetchData } from "../../../../libs/Fetch";

const resultsPerPage = 20;

// TODO move to state
const sorting = "rel_desc";

async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  return response.json();
}

const JobsApiService = {
  /**
   * Fetch search request
   * @param data
   * @param boosting
   * @param page
   * @param seoConfig
   */
  fetchSearch: (
    data = {},
    boosting = [],
    page = 1,
    seoConfig = {},
    isPreview = false
  ) => {
    const url = `${process.env.NEXT_PUBLIC_FRONTEND_DOMAIN}${
      process.env.BASE_PATH ? `${process.env.BASE_PATH}` : ""
    }/api/v1/search/${page}`;
    if (!"resultsPerPage" in data) {
      // set default
      data["resultsPerPage"] = resultsPerPage;
    }

    if (!"sort" in data) {
      // set default
      data["sort"] = sorting;
    }

    let request = { params: data };

    if (
      (boosting && data["sort"] === "rel_desc") ||
      data["sort"] === "rel_asc"
    ) {
      if (!Array.isArray(boosting) && !GeneralUtility.isEmptyObject(boosting)) {
        boosting = [boosting];
      }

      if (boosting.length > 0) {
        request["boosting"] = boosting;
      }
    }

    return fetchData(url, request, "POST");
  },
  fetchSeoSearch: (searchConfig, page = 1) => {
    let url = `${process.env.NEXT_PUBLIC_FRONTEND_DOMAIN}${
      process.env.BASE_PATH ? `/${process.env.BASE_PATH}` : ""
    }/api/v1/search/${page}`;
    let request = { params: searchConfig };
    return postData(url, request).then((resp) => {
      return resp;
    });
  },
};

export default JobsApiService;
