import {
  getBoosting,
  getCurrentPage,
  getJobsHistory,
  getJobsPending,
  getLastVisit,
  getSearchArgs,
  getSearchHistory,
  getSearchMeta,
  getUser,
} from "../reducer";
import {
  fetchJobSearchError,
  fetchJobSearchPending,
  fetchJobSearchSuccess,
  fetchJobSeoSearchSuccess,
  jobmarketInitialized,
  setLastVisit,
} from "../actions";
import GeneralUtility from "../../../libs/GeneralUtility";
import { addSearchToHistory, loadHistory } from "./searchHistory";
import {
  fetchSearch,
  fetchSeoSearch,
  getDefaultSearch,
} from "../services/JobsApiService/index";
import { isEmptyValue } from "../../user/lib/GeneralUtility";
import { hasRole } from "../../user/lib/UserProfileFeatureUtility";
import { getStorage } from "../../../libs/StorageUtility";

/**
 * Trigger search from search form actions
 *
 * @param e
 * @returns {function(*, *)}
 */
export const doSearch = (e) => {
  if (e) {
    e.preventDefault();
  }

  return async (dispatch, getState) => {
    const searchConfig = getSearchArgs(getState());
    dispatch(fetchJobSearch(searchConfig));
  };
};

/**
 * Load more jobs of list view
 *
 * @param inView
 * @param entry
 * @returns {function(*, *)}
 */
export const joblistEndReached = (inView, entry) => {
  return async (dispatch, getState) => {
    if (inView) {
      const lastSearch = getSearchArgs(getState());
      const lastSearchMeta = getSearchMeta(getState());

      switch (lastSearchMeta.type) {
        case "seo":
          dispatch(
            fetchJobSeoSearch(
              lastSearchMeta.seoPageConfig?.slug,
              getCurrentPage(getState()) + 1
            )
          );
          break;
        case "default":
          dispatch(fetchJobSearch(lastSearch, getCurrentPage(getState()) + 1));
          break;
      }
    }
  };
};

export const refetchSearch = () => {
  return async (dispatch, getState) => {
    const lastSearch = getSearchArgs(getState());
    const lastSearchMeta = getSearchMeta(getState());

    switch (lastSearchMeta.type) {
      case "seo":
        dispatch(fetchJobSeoSearch(lastSearchMeta.seoPageConfig?.slug));
        break;
      case "default":
        dispatch(fetchJobSearch(lastSearch));
        break;
    }
  };
};

/**
 * Fetch search
 *
 * @param searchArgs
 * @param page
 * @param isPreview
 * @returns {function(*, *)}
 */
export const fetchJobSearch = (
  searchArgs = {},
  page = 1,
  isPreview = false
) => {
  return async (dispatch, getState) => {
    dispatch(fetchJobSearchPending());

    let isDefaultSearch = false;
    let boosting = [];

    if (Object.keys(searchArgs).length === 0) {
      searchArgs = getDefaultSearch();
      isDefaultSearch = true;
    } else {
      isDefaultSearch = GeneralUtility.isDefaultSearch(searchArgs);
    }

    // on default search we want to boost items matching our latest search history
    if (isDefaultSearch) {
      boosting = getBoosting(getState());
    } else {
      // select facets matching to current query words
      if (searchArgs.query && searchArgs.query !== "") {
        const searchInputWords = GeneralUtility.trim(searchArgs.query)
          .toLowerCase()
          .split(" ");

        // check if query words for cities and transfer it to location field
        // DEACTIVATED: https://trello.com/c/4SsdXMbQ/814-kollision-kanzleiname-bornheim-ort-bornheim
        // for(let i = searchInputWords.length-1; i >= 0;i--) {
        //     const searchInputWord = searchInputWords[i];
        //     const [matchingFacetKey, matchedFacetItem, titleMatch] = getFacetWithValue(getFacets(getState()), searchInputWord, false, ['geodatas']);
        //     if(matchedFacetItem) {
        //         // move detected location facet title to location field
        //         searchArgs.location = matchedFacetItem.title;
        //         searchInputWords.splice(i, 1);
        //         break;
        //     }
        // }

        // check matching facets and select them
        // DEACTIVATED:
        // const [matchingFacetKey, matchedFacetItem, titleMatch] = getFacetWithValue(getFacets(getState()), searchInputWords.join('-'), false, ['activity', 'rechtsgebiete', 'employer']);
        // if(matchingFacetKey) {
        //     const currentFilterOfFacet = searchArgs.filter && matchingFacetKey in searchArgs.filter ? searchArgs.filter[matchingFacetKey] : [];
        //     searchArgs.filter = {
        //         ...searchArgs.filter,
        //         [matchingFacetKey]: [...currentFilterOfFacet, matchedFacetItem.uid]
        //     }
        //
        //     if(titleMatch) {
        //         delete searchArgs.query;
        //     }else {
        //         // add remaining search words to query field
        //         searchArgs.query = searchInputWords.join(' ');
        //     }
        // }else {
        // add remaining search words to query field
        searchArgs.query = searchInputWords.join(" ");
        // }
      }
    }

    await fetchSearch(searchArgs, boosting, page, {}, isPreview)
      .then((data) => {
        const hasRoleMatching = hasRole("matching", getUser(getState()));
        if (!hasRoleMatching) {
          data.result.solr.response.docs = reorderSolrItems([
            ...data.result.solr.response.docs,
          ]);
        }
        dispatch(
          fetchJobSearchSuccess(
            searchArgs,
            data,
            isDefaultSearch,
            getTitleFromLastSearch(data.args, data?.result?.facets),
            new Date().getTime() / 1000
          )
        );
      })
      .catch((error) => {
        dispatch(fetchJobSearchError(error));
      });
  };
};

export const fetchJobSeoSearch = (seoTitle, page = 1, isPreview) => {
  return async (dispatch, getState) => {
    dispatch(fetchJobSearchPending());
    const searchConfig = {
      seoPage: {
        slug: seoTitle,
        doSearch: true,
        isPreview,
      },
    };

    await fetchSeoSearch(searchConfig, page).then((data) => {
      dispatch(
        fetchJobSeoSearchSuccess(
          searchConfig,
          data,
          getTitleFromLastSearch(data.args, data?.result?.facets),
          new Date().getTime() / 1000
        )
      );
    });
  };
};

export const fetchJobsByHistoryItem = (searchConfig) => {
  return async (dispatch, getState) => {
    switch (searchConfig.meta.type) {
      case "seo":
        dispatch(fetchJobSeoSearch(searchConfig.args.seoPage.slug));
        break;
      case "default":
        dispatch(fetchJobSearch(searchConfig.args));
        break;
    }
  };
};

/**
 * Reset search to initial state
 *
 * @returns {function(*, *)}
 */
export const resetSearch = (e) => {
  e.preventDefault();

  return async (dispatch, getState) => {
    dispatch(addSearchToHistory());
    dispatch(fetchJobSearch());
  };
};

/**
 * Init jobmarket after successful load
 *
 * @returns {function(*, *)}
 */
export const initJobmarket = (isSeoPage) => {
  return async (dispatch, getState) => {
    dispatch(loadHistory());
    dispatch(persistLastVisit(Math.floor(Date.now() / 1000)));

    // boost items based on job view history or search history - only trigger boosting if history items exists and no search active
    if (
      !getJobsPending(getState()) &&
      GeneralUtility.isDefaultSearch(getSearchArgs(getState()), false) &&
      !isSeoPage &&
      (getSearchHistory(getState()).length > 0 ||
        getJobsHistory(getState()).length > 0)
    ) {
      dispatch(fetchJobSearch());
    }

    dispatch(jobmarketInitialized(true));
  };
};

/**
 * Load last visit from local storage
 *
 * @returns {function(*, *): Promise<void>}
 */
export const loadLastVisit = () => {
  return async (dispatch, getState) => {
    const storage = getStorage();
    if (storage && getLastVisit(getState()) === 0) {
      const lastVisit = parseInt(storage.getItem("lastVisit"));
      if (lastVisit) {
        dispatch(setLastVisit(lastVisit));
      }
    }
  };
};

/**
 * Load last visit from local storage
 *
 * @returns {function(*, *): Promise<void>}
 */
export const persistLastVisit = (timestamp) => {
  return async (dispatch, getState) => {
    const storage = getStorage();
    if (storage && parseInt(timestamp) !== 0) {
      storage.setItem("lastVisit", parseInt(timestamp));
      dispatch(setLastVisit(timestamp));
    }
  };
};

/**
 * ##### End of operations ##################################################################################
 * #####################################################################################################
 * ##### Start helper functions ########################################################################
 */

const getTitleFromLastSearch = (lastSearchArgs, facets) => {
  let titleItems = [];

  if (lastSearchArgs.queries && lastSearchArgs.queries.length > 0) {
    titleItems = titleItems.concat(lastSearchArgs.queries);
  }

  if (!isEmptyValue(lastSearchArgs.location_current)) {
    titleItems.push(
      lastSearchArgs.location_current + ", " + lastSearchArgs.radius + " km"
    );
  }

  if (facets) {
    let selectedFacetTitles = getSelectedFacetTitles(facets);
    titleItems = titleItems.concat(selectedFacetTitles);
  }

  return titleItems;
};

const getSelectedFacetTitles = (facets) => {
  let selectedFacetItems = [];

  for (const facet in facets) {
    if (facets[facet]["items"]) {
      for (const facetItem in facets[facet]["items"]) {
        if (facets[facet]["items"][facetItem].selected === 1) {
          selectedFacetItems.push(facets[facet]["items"][facetItem].title);
        }
      }
    }
  }
  return selectedFacetItems;
};

/**
 * reorder solr items to prevent two job items of same employer directly after each other
 * @param items
 * @returns {*[]}
 */
const reorderSolrItems = (items) => {
  const reorderStack = [];
  const orderedItems = [];
  for (const index in items) {
    const currentIndex = parseInt(index);
    const item = items[index];

    if (currentIndex === 0) {
      // always push first element to new array
      orderedItems.push(item);
      continue;
    }

    const itemBefore = orderedItems[orderedItems.length - 1];

    if (item.companyname_stringS !== itemBefore.companyname_stringS) {
      // different employer => all good
      orderedItems.push(item);
    } else {
      // park job item to reorder
      reorderStack.push(item);
    }

    // check for parked job items and add them if different employer
    if (reorderStack.length > 0) {
      let firstReorderItem = reorderStack[0];
      do {
        if (
          firstReorderItem.companyname_stringS !==
          orderedItems[orderedItems.length - 1].companyname_stringS
        ) {
          orderedItems.push(firstReorderItem);
          reorderStack.shift();

          if (reorderStack.length === 0) {
            break;
          }
        }
        firstReorderItem = reorderStack[0];
      } while (
        firstReorderItem.companyname_stringS !==
        orderedItems[orderedItems.length - 1].companyname_stringS
      );
    }
  }
  return [...orderedItems, ...reorderStack];
};
